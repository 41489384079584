import {
  WcFormControl,
  WcFormHelperText,
  WcIconButton,
  WcInputAdornment,
  WcStack,
  WcSnackBar,
  WcTextField,
} from "@bpipartners/webui";
import { useTranslations } from "next-intl";
import React from "react";
import { useTheme } from "@guberno/utils/theme";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const checkUppercase = (str: string): boolean => {
  for (let i = 0; i < str.length; i++) {
    if (
      str.charAt(i) === str.charAt(i).toUpperCase() &&
      str.charAt(i).match(/[a-z]/i)
    ) {
      return true;
    }
  }
  return false;
};

const validPassword = (password: string): boolean => {
  const isUpperCase = checkUppercase(password);
  if (password && password.length >= 8 && isUpperCase) {
    return true;
  } else {
    return false;
  }
};

interface ResetPasswordFormProps {
  onResetDetails: (val: ResetPasswordFormState) => void;
  data?: any;
}

export interface ResetPasswordFormState {
  confirmPassword: string;
  password: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

interface ResetPasswordFormError {
  confirmPassword: string;
  password: string;
}

export const ResetPasswordForm = React.forwardRef(
  (props: ResetPasswordFormProps, ref) => {
    const t = useTranslations();
    const { onResetDetails } = props;
    const theme = useTheme();
    const [values, setValues] = React.useState<ResetPasswordFormState>({
      confirmPassword: "",
      password: "",
      showConfirmPassword: false,
      showPassword: false,
    });
    const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [formErrors, setFormErrors] = React.useState<ResetPasswordFormError>({
      confirmPassword: t("feedback.missingValues"),
      password: t("feedback.missingValues"),
    });

    const handleClickShowPassword = () => {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    };

    const handleConfirmShowPassword = () => {
      setValues({
        ...values,
        showConfirmPassword: !values.showConfirmPassword,
      });
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.preventDefault();
    };

    const handleChange =
      (prop: keyof ResetPasswordFormState) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === "confirmPassword" || prop === "password") {
          if (prop === "confirmPassword" && event.target.value === "") {
            formErrors.confirmPassword = t("feedback.missingValues");
          } else {
            if (event.target.value === values.password) {
              formErrors.confirmPassword = "";
            } else {
              formErrors.confirmPassword = t("feedback.passwordMatch");
            }
          }
          if (prop === "password" && event.target.value === "") {
            formErrors.password = t("feedback.missingValues");
          } else {
            const checkPassword = validPassword(values.password);
            if (checkPassword) {
              formErrors.password = "";
            } else {
              formErrors.password = t("feedback.passwordMustContain");
            }
          }
        }
        setFormErrors({ ...formErrors });
        setValues({ ...values, [prop]: event.target.value });
      };

    React.useImperativeHandle(ref, () => ({
      onSubmit() {
        setFormIsSubmitted(true);
        if (Object.values(formErrors).some((item) => item.length > 0)) {
          setSnackBar(true);
          setSeverity("error");
          setSnackBarMessage(t("feedback.missingValues"));
        } else {
          setFormErrors({ password: "", confirmPassword: "" });
          onResetDetails(values);
        }
      },
    }));

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackBar(false);
    };
    return (
      <WcStack spacing={2} padding={2} width={450} alignItems="center">
        <WcStack
          alignItems="center"
          direction={"column"}
          width={350}
          spacing={4}
        >
          <WcFormControl fullWidth variant="outlined">
            <WcTextField
              id="outlined-password"
              label={t("login.password")}
              required
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              size="medium"
              error={formIsSubmitted && formErrors.password.length > 0}
              onChange={handleChange("password")}
              customVariant="default"
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: theme.appColors.surfaceContainerLowest,
                },
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <WcInputAdornment position="end">
                    <WcIconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      color="primary"
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </WcIconButton>
                  </WcInputAdornment>
                ),
              }}
            />
            {formIsSubmitted && (
              <WcFormHelperText
                sx={{
                  color: formErrors.password ? theme.appColors.error : "",
                }}
                id="filled-password-helper-text"
              >
                {formErrors.password ? formErrors.password : ""}
              </WcFormHelperText>
            )}
          </WcFormControl>
          <WcFormControl fullWidth variant="outlined">
            <WcTextField
              id="outlined-adornment-confirmPassword"
              label={t("login.confirmPassword")}
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: theme.appColors.surfaceContainerLowest,
                },
              }}
              type={values.showConfirmPassword ? "text" : "password"}
              error={formIsSubmitted && formErrors.confirmPassword.length > 0}
              value={values.confirmPassword}
              onChange={handleChange("confirmPassword")}
              customVariant="default"
              size="medium"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <WcInputAdornment position="end">
                    <WcIconButton
                      aria-label="toggle password visibility"
                      onClick={handleConfirmShowPassword}
                      color="primary"
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </WcIconButton>
                  </WcInputAdornment>
                ),
              }}
            />
            {formIsSubmitted && (
              <WcFormHelperText
                sx={{
                  color: formErrors.password ? theme.appColors.error : "",
                }}
                id="filled-name-helper-text"
              >
                {formErrors.confirmPassword ? formErrors.confirmPassword : ""}
              </WcFormHelperText>
            )}
          </WcFormControl>
        </WcStack>
        <WcSnackBar
          openState={snackBar}
          //@ts-ignore
          severity={severity}
          message={snackBarMessage}
          //@ts-ignore
          onClose={handleClose}
        ></WcSnackBar>
      </WcStack>
    );
  }
);

ResetPasswordForm.displayName = "ResetPasswordForm";

export default ResetPasswordForm;
