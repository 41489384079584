import { useTranslations } from "next-intl";
import React from "react";
import { useTheme } from "@guberno/utils/theme";
import {
  WcTextField,
  WcStack,
  WcIconButton,
  WcSnackBar,
  WcFormControl,
  WcFormHelperText,
  WcInputAdornment,
} from "@bpipartners/webui";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { isEmail } from "validator";
import { IoMdCloseCircleOutline } from "react-icons/io";

interface LoginFormProps {
  onLoginDetails: (val: LoginFormState) => void;
  data?: any;
}

export interface LoginFormState {
  userName: string;
  password: string;
  showPassword: boolean;
}

interface LoginFormError {
  userName: string;
  password: string;
}

export const LoginForm = React.forwardRef((props: LoginFormProps, ref) => {
  const t = useTranslations();
  const { onLoginDetails } = props;
  const theme = useTheme();
  const [values, setValues] = React.useState<LoginFormState>({
    userName: "",
    password: "",
    showPassword: false,
  });

  const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [formErrors, setFormErrors] = React.useState<LoginFormError>({
    userName: "",
    password: "",
  });

  const handleChange =
    (prop: keyof LoginFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === "userName" || prop === "password") {
        if (prop === "userName") {
          if (event.target.value === "") {
            formErrors.userName = t("feedback.missingValues");
          } else {
            if (!isEmail(event.target.value)) {
              formErrors.userName = t("feedback.invalidEmail");
            } else {
              formErrors.userName = "";
            }
          }
        }
        if (prop === "password" && event.target.value === "") {
          formErrors.password = t("feedback.missingValues");
        } else {
          formErrors.password = "";
        }
      }
      setFormErrors({ ...formErrors });
      setValues({ ...values, [prop]: event.target.value.trim() });
    };

  React.useImperativeHandle(ref, () => ({
    onSubmit() {
      setFormIsSubmitted(true);
      if (Object.values(formErrors).some((item) => item.length > 0)) {
        setSnackBar(true);
        setSeverity("error");
        setSnackBarMessage(t("feedback.missingValues"));
      } else {
        setFormErrors({ password: "", userName: "" });
        onLoginDetails(values);
      }
    },
  }));

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar(false);
  };

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (event.key === "Enter") {
      // @ts-ignore
      const { value } = event.target;
      if (value === "") {
        formErrors.password = t("feedback.missingValues");
      } else {
        formErrors.password = "";
      }
      if (values.userName === "") {
        formErrors.userName = t("feedback.missingValues");
      } else {
        if (values.userName && !isEmail(values.userName)) {
          formErrors.userName = t("feedback.invalidEmail");
        } else {
          formErrors.userName = "";
        }
      }
      setFormErrors({ ...formErrors });
      setValues({
        ...values,
        password: value,
        userName: values.userName.trim(),
      });
      setFormIsSubmitted(true);
      if (Object.values(formErrors).some((item) => item.length > 0)) {
        setSnackBar(true);
        setSeverity("error");
        setSnackBarMessage(t("feedback.missingValues"));
      } else {
        setFormErrors({ password: "", userName: "" });
        onLoginDetails(values);
      }
    }
  };
  return (
    <WcStack
      data-cy="loginForm"
      id="LoginForm"
      spacing={2}
      padding={2}
      width={450}
      alignItems="center"
    >
      <WcStack alignItems="center" direction={"column"} width={350} spacing={4}>
        <WcFormControl variant="outlined" fullWidth>
          <WcTextField
            data-cy="userName"
            id="outlined-userName"
            label={t("labels.userName")}
            type="email"
            required
            value={values.userName}
            onChange={handleChange("userName")}
            customVariant="default"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <WcInputAdornment position="end">
                  {values.userName.length > 0 && (
                    <WcIconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setValues({
                          ...values,
                          userName: "",
                        })
                      }
                      color="primary"
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      <IoMdCloseCircleOutline />
                    </WcIconButton>
                  )}
                </WcInputAdornment>
              ),
            }}
            size="medium"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: theme.appColors.surfaceContainerLowest,
              },
            }}
          />
          <WcFormHelperText
            sx={{
              color: formErrors.userName ? theme.appColors.error : "",
            }}
            id="filled-userName-helper-text"
          >
            {formErrors.userName ? formErrors.userName : " "}
          </WcFormHelperText>
        </WcFormControl>
        <WcFormControl variant="outlined" fullWidth>
          <WcTextField
            fullWidth
            id="outlined-adornment-password"
            label={t("labels.password")}
            data-cy="password"
            required
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onKeyDown={onKeyPressed}
            customVariant="default"
            variant="outlined"
            size="medium"
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: theme.appColors.surfaceContainerLowest,
              },
            }}
            InputProps={{
              endAdornment: (
                <WcInputAdornment position="end">
                  <WcIconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    color="primary"
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {values.showPassword ? (
                      <MdVisibilityOff />
                    ) : (
                      <MdVisibility />
                    )}
                  </WcIconButton>
                </WcInputAdornment>
              ),
            }}
            onChange={handleChange("password")}
          />
          <WcFormHelperText
            sx={{
              color: formErrors.password ? theme.appColors.error : "",
            }}
            id="filled-name-helper-text"
          >
            {formErrors.password ? formErrors.password : " "}
          </WcFormHelperText>
        </WcFormControl>
      </WcStack>
      <WcSnackBar
        openState={snackBar}
        //@ts-ignore
        severity={severity}
        message={snackBarMessage}
        //@ts-ignore
        onClose={handleClose}
      ></WcSnackBar>
    </WcStack>
  );
});

LoginForm.displayName = "LoginForm";

export default LoginForm;
